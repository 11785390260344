.calendar{
    select{
        webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
        @extend .font-small;
    }
    table {
        &.calendar{
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 2px;
    
            th {
                border: 0px !important;
                @include media-breakpoint-down(lg) {
                    transform: rotate(-90deg);
                }
            }
        
            td {
    
                width: 14%;
                font-size: 11px;
                min-height: 120px;
                overflow-y: auto;
                border: 0px !important;
                background-color: $lightest_grey;
                @include media-breakpoint-down(lg) {
                    padding:0;
                }
                
                &.calendar-cell {
                    background-color: $off_white;
                    border: none;
                }
        
                div {
                    background-color: $white;
                    padding: 10px;
                    margin-bottom: 2px;
                    line-height: 20px;
                    span {
                    }
                }
        
                a {
                    color: $black;
                    &:hover {
                        div {
                            button{
                                background: $orange !important;
                            }
                        }
                    }
                }
            }
        }
        
    }
}
