
form{

    input{
        &.form-control{
            padding: 0.4rem 0.75rem;
            background-color: $off_white;
            color: $black;
            border:1px solid $nepal;
            @extend .border-radius;
            @extend .font-smallest;
        }
        &[type=file] {
            margin-top: 5px;
        }
        &.currency {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>£</text></svg>");
            padding-left: 25px;
            border: 1px solid #e5e6e7;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-position: 7px center;
            &.currency-pound {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>£</text></svg>");
            }
            &.currency-dollar {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>$</text></svg>");
            }
            &.currency-euro {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>€</text></svg>");
            }
            &.currency-zar {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>R</text></svg>");
            }
            &.currency-sar {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='3' y='14' fill='black' font-size='12' font-family='arial'>SR</text></svg>");
                padding-left: 32px;
            }
          }
    }

    textarea{
        &.form-control{
            padding: 0.5rem 0.75rem;
            background-color: $off_white;
            color: $black;
            border:1px solid $nepal;
            @extend .border-radius;
            @extend .font-smallest;
        }
    }

    .input-group-addon{
        padding: 0.4rem 0.75rem;
        background-color: $off_white;
        color: $black;
        border:1px solid $nepal;
        @extend .border-radius;
        @extend .font-smallest;
    }
    
    .form-block{
        margin-bottom: 2rem;
        position: relative;
        
        &::after{
            content: "";
            display: block;
            width: 100%;
            padding-top: 2rem;
            margin-right: calc(var(--bs-gutter-x) * 0.5);
            margin-left: calc(var(--bs-gutter-x) * 0.5);
            border-bottom: 1px solid $light_grey;
            opacity: 0.25;
        }
    }
    .form-group{
        margin-bottom: 1rem;
        label{
            width: 100%;
        }
        &.has-error{
            label, p, span{
                color: $red;
            }
            input, textarea, .select2-container .select2-selection{
                border-color: $red;
            }
        }
        img{
            &.form-image{
                max-width: 120px;
            }
        }
    }

    .variation_available_wrap, .buyer_available_wrap {
        position: relative;
        span#variation_available, span#buyer_available {
          position: absolute;
          top: 1rem;
          left: 5rem;
          display: none;
          font-weight: bold;
          color: $green;
        }
      }
      
            
      .invoice_variation, .invoice_buyer{
        display: none;
        
      }
      
      .visible_variation, .visible_buyer {
        display: block;
      }
      
}

/* SELECT AJAX DIV */
.select2-container{
    .select2-selection{
        min-height: 34px;
        padding: 0 0.2rem;
        background-color: $off_white;
        color: $black;
        border:1px solid $nepal;
        @extend .border-radius;
        @extend .font-smallest;
        .select2-search--inline {
            .select2-search__field{
                margin-top: 3px;
            }
        }
        .select2-selection__rendered{
            @extend .font-smallest;
            line-height: 34px;
            .select2-selection__choice{
                line-height: 25px;
                margin-top: 7px;
            }
            .select2-selection__clear{
                margin-top: 5px;
            }
        }
        .select2-selection__clear{
            line-height: 25px;
        }
        .select2-selection__arrow{
            height: 35px;
        }
        &.select2-selection--multiple{
            min-height: 31px;
            line-height: 10px;
            .select2-selection__rendered{
                line-height: 10px;
                .select2-search--inline{
                    .select2-search__field{
                        line-height: 25px;
                    }
                }
                .select2-selection__choice{
                    line-height: 25px;
                    margin-top: 3px;
                    background-color: $lightest_grey;
                    white-space: initial;
                }
            }

            
        }
    }
    .select2-dropdown{
        background-color: $off_white;
        color: $mid_grey;
    }
    .select2-results{
        color: $mid_grey;
        @extend .font-smallest;
    }
    input.select2-search__field{
        @extend .font-smallest;
        @extend .border-radius;
    }
}


/* PARCIALS*/
.disabled-form-block {
    background-color: #f2f2f2;
    border: 1px solid #e7e7e7;
    padding-top: 1rem;
    &:after {
        display: none !important;
    }
}