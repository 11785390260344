.complete-v2{

    .dataTables_wrapper{
        table{
            &.dataTable{
                @extend .font-smallest;
                &.no-footer{
                    border: none;
                }
                thead{
                    th{
                        background-color: $navy;
                        color: $white;
                        padding: 0.5rem 1rem 0.5rem 0.5rem;
                        border: none;
                        @extend .font-smallest;
                        &::before, &::after{
                            content: "";
                        }
                    }
                }
                tbody{
                    tr{
                        &.odd{
                            td{
                                background-color: $black_5;
                            }
                        }
                        td{
                            border: none;
                            padding: 0.5rem 0.5rem;
                            box-shadow: none;
                        }
                        img{
                            max-height: 1.5rem;
                        }
                        &:hover{
                            background-color: $black_10;
                        }
                    }
                }
            }
        }
        .dataTables_info{
            @extend .font-tiny;
        }
        .dataTables_paginate{
            text-align: end;
            @extend .font-tiny;
            ul{
                @extend .border-radius;
                li{
                    padding: 0;
                    margin: 0;
                    border-radius: 0;
                    border:none;
                    a{
                        display: inline-block;
                        margin:0;
                        padding: 0.15rem 0.5rem;
                        background-color: $white;
                        color: $navy;
                        border-radius:initial;
                        &:focus{
                            box-shadow: none;
                        }
                        &:hover:not(.current):not(.disabled){
                            cursor: pointer;
                            background-color: $green;
                            color: $white;
                        }
                    }
                    &.disabled{
                        border:none;
                        &:hover{
                            border:none;
                        }
                        a{
                            color: $light_grey;
                        }
                    }
                    &.active{
                        a{
                            background-color: $navy;
                            color: $white;
                            border-color: $navy;
                        }
                    }
                    &:hover{
                        background: none;
                        border:none;
                    }
                }
            }
        }
        
    }
    
    .dataTables_length{
        @extend .font-tiny;
        text-align: center;
        select{
            display: inline-block;
            width: auto;
            padding: 0.5rem 1.25rem 0.5rem 0.75rem;
            background-color: $off_white;
            background-position: right 0.25rem center;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23171717' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            color: $dark_grey;
            border:none;
            @extend .border-radius;
        }
    }
    .dataTables_filter, .search{ 
        @extend .font-tiny;
        text-align: end;
        label{
            width: 100%;
            @include media-breakpoint-down(lg) {
                text-align: center !important;
            }
        }
        input{
            width: 100%;
            padding: 0.5rem 0.75rem;
            background-color: $off_white;
            color: $black;
            border:none;
            @extend .border-radius;
        }
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before{
        background-color: $navy;
        box-shadow: none;
        line-height: 16px;
        width: 14px;
        height: 15px;
        padding-right: 1px;
        
    }
    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:hover:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:hover:before{
        background-color: $green;
    }
    .tab-pane{
        .dataTables_wrapper{
            // justify-content: flex-end;

            .dataTables_length, .dataTables_filter{
                padding: 1rem 0 1rem 1rem;
                input{
                    margin: 0;
                }
            }
        }
    }

}