#toast-container{
    &.toast-top-right {
        top: 6rem;
        right: 1.4rem;
    }
    .toast{
        opacity: 1;
        &.toast-success{
            background-color: $green;
        }
        &.toast-error{
            background-color: $red;
        }
        &.toast-info{
            background-color: $blue;
        }
    }
    
}
