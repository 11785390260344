.error-page{
    height: 100vh;
    img{
        margin-bottom: 2rem;
        max-width: 200px;
    }
    .content{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        a{
            color: $orange;
        }
    }
    
}