.security{
    .panel{
        &.security-nav{
            padding: 1rem;
            ul.nav{
                @extend .font-smallest;
                border: none;
                li{
                    button{
                        display: flex;
                        width: 100%;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;
                        color: $mid_grey;
                        position: relative;
                        border: none;
                        text-align: start;
                        font-weight: 600;

                        &:hover{
                            border: none;
                            color: $white;
                        }

                        &.active{
                            border: none;
                            color: $white;
                            background-color: $white_10;
                        }
                        
                    }
                }
            }
        }
        table{
            tr{
                th{
                    &:not(:first-child){
                        width: 75px;
                    }
                }
            }
        }
    }
}