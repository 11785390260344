.main-nav{
    background-color: $dark_grey;
    @include media-breakpoint-up(lg) {
        min-height: 100vh;
        padding-left: 0;
    }
    
    .client-logo{
        text-align: center;
        @include media-breakpoint-up(lg) {
            padding: calc(var(--bs-gutter-x) * 0.5) 0 1rem calc(var(--bs-gutter-x) * 0.5);
        }
        img{
            width: 100%;
            max-width: 200px;
            height: auto;
            @include media-breakpoint-down(lg) {
                width: auto;
                max-height: 5vh;
            }
        }
        @include media-breakpoint-down(lg) {
            // margin-bottom: 2rem;
        }
    }

    nav{
        .navbar-collapse{
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
        ul{
            ul{
                display: none;
            }
            li{
                width: 100%;
                a{
                    display: block;
                    position: relative;
                    color: $white;
                    padding: 0.75rem 0 0.75rem 1.5rem;
                    @extend .font-smallest;
                    @extend .transition;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 5px;
                        height: 100%;
                        background-color: $orange;
                        opacity: 0;
                        @extend .transition;
                    }
                    &:hover{
                        color: $orange;
                    }
                    i{
                        width: 0.75rem;
                        margin-right: 0.75rem;
                        color: $light_grey;
                    }
                }
                &.active{
                    a{
                        background-color: $white_5;
                        &::before{
                            opacity: 1;
                        }
                    }
                    ul{
                        display: block;
                        li{
                            a{
                                padding: 0.25rem 0 0.25rem 3.5rem;
                            }
                            &:first-child{
                                a{
                                    padding-top: 0;
                                }
                            }
                            &:last-child{
                                a{
                                    padding-bottom: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    .navbar-brand{
        width: 100%;
        margin-right: 0;
        @include media-breakpoint-down(lg) {
            width: auto;
        }
    }

    .navbar-toggler{
        @include media-breakpoint-up(lg) {
            display: none;
        }
        .navbar-toggler-icon{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            @extend .transition;
    
            .bar{
                // display: none;
                width: 100%;
                height: 3px;
                background-color: $white;
                @extend .transition;
                &.bar-1{
                    opacity: 0;
                }
                &.bar-2{
                    width: 100%;
                }
                &.bar-3{
                    opacity: 0;
                    width: 50%;
                }
            }

        }
        &.collapsed{
            .navbar-toggler-icon{
                justify-content: space-around;
                .bar{
                    opacity: 1;
                    &.bar-2{
                        width: 75%;
                    }
                }
            }
        }
    }
    
}