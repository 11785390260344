
.datepicker{
    @extend .font-smallest;
    table{
        tr{
            td{
                span{
                    height: 35px;
                    line-height: 35px;
                }
            } 
        } 
    } 
}