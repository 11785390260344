.workflow{

    .stats{
        span{
            &.badge{
                padding: 0.5rem 1rem;
                margin-bottom: 1rem;
            }
        }
        p{
            display: block;
            margin: 0;
            @extend .font-tiny-mid-smallest;
        }
    }
    .ratio{
        &.ratio-21x9{
            --bs-aspect-ratio: 20%;
            #chart{
                >div{
                    height: calc( 100% - 20px);
                    overflow: hidden;
                }
            }
        }
    }
    
}
