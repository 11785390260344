$black: rgb(0,0,0);
$white: rgb(255,255,255);

$dark_grey: rgba(23, 23, 23, 1);
$mid_dark_grey: rgb(67, 74, 90);
$mid_grey: rgba(112, 112, 112, 1);
$light_grey : rgb(146, 146, 157);
$lightest_grey: rgb(242, 242, 242);
$off_white: rgba(242, 244, 247, 1);

$orange: rgba(255, 167, 0, 1);
$dark_orange: rgba(249, 158, 20, 1);
$red: rgb(247, 71, 71);
$light_red: rgb(235, 86, 104);
$navy: rgba(6, 15, 41, 1);
$navy_grey: rgba(24, 29, 46, 1);
$green: rgba(92, 201, 165, 1);
$nepal: rgba(142, 174, 188, 1);
$blue: rgb(22, 157, 255);
$light_blue: rgba(162, 233, 237, 1);
$cornflow_blue: rgba(90, 96, 127, 1);
$purple: rgba(208, 69, 176, 1);
$blue_grey: rgb(145, 150, 172);

$red_orange_gradient: linear-gradient(90deg, rgba(167,48,85,1) 0%, rgba(173,48,91,1) 25%, rgba(211,61,78,1) 50%, rgba(227,92,76,1) 75%, rgba(219,161,81,1) 100%);
$red_orange_gradient_hover: linear-gradient(90deg, rgba(167,48,85,1) 0%, rgba(173,48,91,1) 15%, rgba(211,61,78,1) 30%, rgba(227,92,76,1) 45%, rgba(219,161,81,1) 200%);

$black_5: rgba(0,0,0,0.05);
$black_10: rgba(0,0,0,0.1);
$black_15: rgba(0,0,0,0.15);
$white_5: rgba(255,255,255,0.05);
$white_10: rgba(255,255,255,0.1);

