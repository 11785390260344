html, body{
    min-height: 100vh;
    background-color: $light_grey;
    color: $white;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    
    &.modal-open{
        .select2-container{
            &.select2-container--open{
                z-index: 9999;
                min-width: 200px;
            }
        }
        
    }
    &.clean{
        background-color: $dark_grey;
    }
}

html {
    scroll-behavior: auto !important;
}

img{
    max-width: 100%;
    &.img-circle{
        border-radius: 100%;
        max-height: 40px;
    }
}

hr.seperator{
    background-color: $light_grey;
}

.border-radius{
    border-radius: 4px;
}

.border-radius-left{
    border-radius: 4px 0 0 4px;
}

.border-radius-right{
    border-radius: 0 4px 4px 0;
}

a{
    text-decoration: none;
    color: $mid_grey;
    @extend .transition;
    &:hover{
        color: $orange;
    }
}

.transition{
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.fa{
    &.large-icon{
        @extend .font-largest;
    }
}

.main-content-wrapper, .modal{
    position: relative;
    min-height: 100%;
    background-color: $lightest_grey;
    @extend .font-smallest;
    &::before{
        content: "";
        position: fixed;
        width: 20vw;
        height: 70vh;
        top: 10vh;
        right: 0;
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        @extend .brand-circles-large;
    }
    .main-content, .modal-content{
        position: relative;
        // z-index: 1;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .page-heading{
            position: relative;
            h2{
                margin: 0;
                @extend .font-largest;
                color: $dark_grey;
                font-weight: 600;
                letter-spacing: 0.05rem;
            }
            .breadcrumb{
                @extend .font-smallest;
                li{
                    a{
                        &::after{
                            content: "/";
                            display: inline-block;
                            padding: 0 0.25rem;
                            color: $dark_grey;
                        }
                    }
                    &.active{
                        color: $light_red;
                    }
                }
                
            }
        }
        .panel{
            padding: 2rem;
            background-color: $white;
            color: $cornflow_blue;
            @include media-breakpoint-down(lg) {
                padding: 1rem;
            }
            @extend .transition;
            &:not(:last-child){
                margin-bottom: 1rem;
            }
            @extend .border-radius;
            &.panel-white{
                background-color: $white;
                h1,h2,h3,h4,h5,h6{
                    color: $dark_grey;
                }
            }
            &.panel-navy{
                position: relative;
                background-color: $navy;
                color: $white;
                overflow: hidden;
                h1,h2,h3,h4,h5,h6{
                    color: $white;
                }
                &.brand-cirles{
                    &::before{
                        content: "";
                        position: absolute;
                        width: 250px;
                        height: 250px;
                        bottom: -185px;
                        right: -152px;
                        background-position: top left;
                        background-size: cover;
                        background-repeat: no-repeat;
                        z-index: 0;
                        transform: rotate(-45deg);
                        @extend .brand-circles-large;
                    }
                }
            }
            &.panel-lightest-grey{
                background-color: $lightest_grey;
                color: $dark_grey;
            }
            &.panel-lightest-grey{
                background-color: $lightest_grey;
                color: $dark_grey;
            }
            &.panel-dark-mid-grey{
                background-color: $mid_dark_grey;
                color: $white;
            }
            &.panel-dark-grey{
                background-color: $dark_grey;
                color: $white;
            }
            h2{
                position: relative;
                i{
                    position: absolute;
                    right: 0;
                    top:-0.5rem;
                    font-size: 2rem;
                    color: $cornflow_blue;
                }
            }
            h3{
                position: relative;
                i{
                    position: absolute;
                    right: 0;
                }
            }
            p.help, span.help{
                margin: 0;
                @extend .font-tiny;
            }
            .panel-header-inner{
                min-height: 5.5rem;
                .btn{
                    &:last-child(){
                        margin-bottom: 0;
                    }
                }
            }
        }
        .btn{
            padding: 0.5rem 1rem;
            border:none;
            font-family: 'Poppins';
            font-weight: 500;
            @extend .font-smallest;
            &.btn-sm{
                padding: 0.5rem 0.5rem;
                @extend .font-tiny;
            }
            &.btn-square{
                padding: 0.25rem 0.5rem;
                @extend .font-tiny;
            }
            &.btn-success{
                background-color: $navy;
                &:hover{
                    color: $white;
                    background-color: $green;
                }
            }
            &.btn-primary{
                background-color: $green;
                &:hover{
                    color: $dark_grey;
                }
            }
            &.btn-featured{
                color: $white;
                background: $red_orange_gradient;
                &:hover{
                    background: $orange;
                }
            }
        }

        .badge{
            font-family: 'Poppins';
            @extend .font-tiny-mid-smallest;
            &.badge-final{
                background-color: $dark_orange;
            }
            &.badge-signed{
                background-color: $green;
            }
            &.badge-draft{
                background-color: $blue;
            }

            &.badge-expiring, &.badge-terminated{
                background-color: $red;
            }
            &.badge-expired, &.badge-terminating{
                background-color: $dark_orange;
            }
            &.badge-extended{
                background-color: $light_blue;
            }
            &.badge-active{
                background-color: $green;
            }

            &.badge-green, &.badge-g, &.badge-primary{
                background-color: $green;
            }
            &.badge-blue{
                background-color: $blue;
            }
            &.badge-red, &.badge-r, &.badge-danger{
                background-color: $red;
            }
            &.badge-orange, &.badge-a{
                background-color: $dark_orange;
            }
            &.badge-grey{
                background-color: $mid_grey;
            }
            &.badge-yellow{
                background-color: $yellow;
            }

        }
        
    }
}
