.dashboard{

    .main-content{
        .chart{
            width: 100%;
            height: 300px;
            >div{
                max-height: 280px;
                overflow: hidden;
            }
            .placeholder{
                width: 100%;
                height: 300px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                @extend .charts-placeholder;
            }
        }
    
        .panel.live-stats{
            margin-bottom: 1rem;
            margin-top: 0;

            h3{
                @extend .font-smallest;
            }
    
            h4{
                color: $green;
                @extend .font-largest;
                &.expired{
                    position: relative;
                    color: $light_red;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -1px;
                        height: 100%;
                        width: 1px;
                        background-color: $mid_grey;
                    }
                }
            }
    
            p{
                margin: 0;
                color: $blue_grey;
            }
        }
    
        .workflow{
            span:not(.badge){
                display: block;
                margin-top: 1rem;
            }
        }
    
        .learn{
            hr{
                margin: 2rem 0;
                background-color: $yellow;
            }
            p{
                color: $blue_grey;
            }
        }
    }

    &.dashboard-video_guides{
        .panel{
            &.video-guides-nav{
                padding: 1rem;
                ul.nav{
                    @extend .font-smallest;
                    border: none;
                    li{
                        button{
                            display: flex;
                            width: 100%;
                            justify-content: flex-start;
                            align-items: center;
                            color: $mid_grey;
                            position: relative;
                            border: none;
                            text-align: start;
                            font-weight: 600;
                            padding: 0.25rem 0.25rem;

                            i{
                                margin-right: 1rem;
                                position: relative;
                                top: 2px;
                            }

                            &:hover{
                                border: none;
                                color: $white;
                            }

                            &.active{
                                border: none;
                                color: $white;
                                background-color: $white_10;
                            }
                            
                        }
                    }
                }
            }
            .video-cont{
                position:relative;
                padding:48.75% 0 0 0;
                overflow: hidden;
                iframe{
                    width: calc( 100% + 1px ) !important;
                }
            }
        }
    }

}
