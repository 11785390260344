.documents-edit, .documents-show, .cidb-edit, .cidb-show, .goodsservices-edit, .goodsservices-show, .reports-view{
    .panel{
        &.details-nav{
            padding: 1rem;
            ul.nav{
                @extend .font-smallest;
                border: none;
                li{
                    button{
                        display: flex;
                        width: 100%;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;
                        color: $mid_grey;
                        position: relative;
                        border: none;
                        text-align: start;
                        font-weight: 600;

                        &:hover{
                            border: none;
                            color: $white;
                        }

                        &.active{
                            border: none;
                            color: $white;
                            background-color: $white_10;
                        }
                        
                    }
                }
            }
        }
        &.contract-finance{
            @extend .font-smallest;
            h3{
                @extend .font-smallest;
            }
            h4{
                @extend .font-largest;
            }
            p{
                color: $mid_grey;
            }
        }

        .contract-status{
            .badge{
                position: relative;
                top: -2px;
                margin-right: 0.5rem;
            }
        }

        .dropzone{
            min-height: inherit;
            padding: 0.375rem 0.75rem;
            background-color: $off_white;
            color: $black;
            border: none;
            @extend .border-radius;
            .dz-message{
                text-align: start;
                h4{
                    color: $cornflow_blue;
                    font-size: 0.8rem;
                }
            }
        }

        .tab-pane{
            &#tab-notes{
                
            }
        }
        
    }
    
    
}