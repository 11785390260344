.messaging{
    .mailbox-folders{
        margin: 0;
        padding: 0;
        list-style-type: none;
        li{
            a{
                display: block;
                padding: 0.5rem 1rem;
                
                
                span{
                    &.badge{
                        position: relative;
                        top: -3px;
                        border-radius: 100%;
                        font-size: 0.5rem;
                    }
                }
                &.active{
                    color: $white;
                    background-color: $white_10;
                }
                &:hover{
                    color: $white;
                }
            }
        }
    }

    &.messaging-show{
        .list-group-item{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .table-mail {
        tr {
            &.unread{
                color: $black;
                font-weight: bold;
                td {
                    a {
                        font-weight: bold;
                        color: $black;
                    }
                    &.mail-ontact{
                        position: relative;
                        &::before{
                            content: 'new';
                            display: block;
                            position: absolute;
                            font-size: 0.65rem;
                            left: -2rem;
                            top: 0.6rem;
                            background-color: $red;
                            border-radius: 20px;
                            padding: 0 0.3rem;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}




