.nav-top{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $white;
    -webkit-box-shadow: 0 1px 5px 1px $black_15;
    box-shadow: 0 1px 5px 1px $black_15;
    color: $mid_grey;
    @extend .font-smallest;

    .search{
        form{
            display: flex;
            flex-direction: row;
            align-items: center;
            i{
                margin-right: 0.5rem;
            }
            input{
                border: none;
                @extend .font-smallest;
            }
        }
    }

    nav{
        justify-content: flex-end;
        ul{
            li{
                margin-left: 1.5rem;
                a{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                    i, img{
                        margin-right: 0.25rem;
                        @extend .font-normal;
                    }
                    &.count-info{
                        position: relative;
                        span{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            top: 0;
                            right: -6px;
                            width: 15px;
                            height: 15px;
                            background-color: $red;
                            border-radius: 100%;
                            font-size: 0.5rem;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}