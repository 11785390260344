.organisation{
    &.organisation-index, &.organisation-show{

        .organisations-top{
            .stats{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                align-content: space-around;
                justify-content: space-between;
                align-items: flex-start;
                li{
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    h2{
                        margin: 0;
                        text-align: center;
                        color: $mid_grey;
                    }
                }
            }
        }

        /*.panel{
            &.levels-nav{
                ul.nav{
                    @extend .font-smallest;
                    border: none;
                    flex-direction: column;
                    li{
                        button{
                            display: flex;
                            width: 100%;
                            align-content: center;
                            justify-content: space-between;
                            align-items: center;
                            color: $mid_grey;
                            position: relative;
                            border: none;
                            text-align: start;
                            font-weight: 600;
                            padding-left: 0;
                            padding-right: 0;
    
                            &:hover{
                                border: none;
                                color: $white;
                            }
    
                            &.active{
                                border: none;
                                color: $white;
                                background-color: $white_10;
                            }
                            
                        }
                    }
                }
            }
        }*/
        .organisation-level{
            &.panel{
                padding: 1rem;
                h4{
                    margin: 0;
                    @extend .font-normal;
                    font-weight: 600;
                    span.filter{
                        font-weight: 400;
                        color: $red;
                    }
                }
                .reset{
                    h3{
                        display: none;
                    }
                }
            }
            
        }
        .filted-out{
            display: none;
        }
        .organisation-panel{
            position: relative;
            padding: 1rem;
            border: none;
            align-items: center;
            @extend .transition;

            .organisation-actions{
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 1.5rem;
                a{
                    display: flex;
                    background: $red_orange_gradient;
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                    margin-bottom: 3px;
                    border-radius: 100%;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.6rem;
                    @extend .transition;
                    i{
                        line-height: 100%;
                        color: $white;
                    }
                    &:hover{
                        cursor: pointer;
                        background: $orange;
                    }
                }
            }
            
            .organisation-initials{
                display: flex;
                width: 8vw;
                height: 8vw;
                margin-bottom: 1rem;
                font-size: 2vw;
                background-color: $blue_grey;
                color: $white;
                // padding: 0.25rem;
                border: 3px solid $cornflow_blue;
                border-radius: 100%;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
            }
            .organisation-logo{
                // padding: 0.25rem;
                width: 8vw;
                height: 8vw;
                margin-bottom: 1rem;
                border: 3px solid $cornflow_blue;
                border-radius: 100%;
                background-color: $white;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            h3{
                margin-bottom: 0.25rem;
            }
            h4{
                margin: 0;
                color: $mid_grey !important;
                @extend .font-smallest;
            }
        }

        .organisation-level-cont:not(.organisation-level-cont-top){
            display: none;
        }
    }

}