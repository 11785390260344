@import url('https://fonts.googleapis.com/css?family=Montserrat|Poppins');


.font-tiny{
    font-size: 0.6rem;
}
.font-tiny-mid-smallest{
    font-size: 0.75rem;
}
.font-smallest{
    font-size: 0.8rem;
}
.font-small{
    font-size: 0.9rem;
}
.font-normal{
    font-size: 1rem;
}
.font-large{
    font-size: 1.2rem;
}
.font-largest{
    font-size: 1.5rem;
}
.font-huge{
    font-size: 2.5rem;
}

h1, h2, h3, h4, h5, h6{
    margin-bottom: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
}

h1{
    @extend .font-largest;
}
h2{
    @extend .font-large;
}
h3{
    @extend .font-normal;
}
h4{
    @extend .font-small;
}
h5{
    @extend .font-smallest;
}
h6{
    @extend .font-smallest;
}