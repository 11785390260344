.modal_actions {
    .modal-header {
        border: none;
    }
    .modal-footer {
        border: none;
    }
    .modal-body {
        background-color: #fff;
        padding: 0 30px 0 30px;
    }
    input.fieldname {
    float:left;
    clear:left;
    display:block;
    margin:5px;
    }
    input.remove, input.removeOldInput{
        float:left;
        display:block;
        margin:5px;
    }
    .label_with_legend_input_wrap {
        width: 50%;
    }
}

#modalView {
    .pdf-wrap-hide {
        display: none;
    }
}

#side-panel-modal {
    .modal-body{
        overflow-y: auto;
    }
}

body.modal-open{
    #modal{
        position: fixed;
    }
}
