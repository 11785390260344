.login{
    background-color: $dark_grey;
    overflow: hidden;
    >div{
        position: relative;
        z-index: 1;
    }
    &::after{
        content: "";
        position: absolute;
        width: 50vw;
        height: 50vw;
        top: -32vw;
        right: -32vw;
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        transform: rotate(-45deg);
        @extend .brand-circles-large;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .login-form-title{
        padding: 5rem 0;
        h1{
            font-weight: 700;
            @extend .font-huge;
        }
        @include media-breakpoint-down(lg) {
            padding: 2rem 0;
        }
        p{
            color: $mid_grey;
        }
    }

    .client-logo{
        text-align: center;
        position: relative;
        @include media-breakpoint-down(lg) {
            margin: 3.5rem 0 3rem;
        }
        .client-logo-outer{
            &::before{
                content: "";
                position: absolute;
                width: 250%;
                height: 250%;
                top: -75%;
                left: -75%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                @extend .brand-circles-small;
            }
            img{
                width: 8rem;
                height: auto;
                @extend .transition;
            }
        }
    }

    .login-form{
        position: relative;
        padding: 0 5rem;

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background-color: $mid_grey;
        }
        
        .input-bg{
            padding: 1px;
            margin-bottom: 1rem;
            @extend .border-radius;
            background: $red_orange_gradient;

            input{
                padding: 1rem;
                background: $dark_grey;
                border: none;
                color: $white;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                border: none;
                -webkit-text-fill-color: $white;
                -webkit-box-shadow: 0 0 0px 1000px $dark_grey inset;
                transition: background-color 5000s ease-in-out 0s;
            }
            
        }
        label{
            @extend .font-smallest;
            color: $white;
        }
        button{
            margin: 1rem 0;
            padding: 1rem;
            width: 100%;
            @extend .border-radius;
            background: $red_orange_gradient;
            border: none;
            text-transform: uppercase;
            @extend .transition;
            &:hover{
                background: $orange;
            }
        }

        .forgot{
            @extend .font-smallest;
            a{
                color: $white;
                &:hover{
                    color: $orange;
                }
            }
        }

        
    }

    .complete-logo{
        margin: 5rem 0;
        max-width: 7rem;
        filter: none;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        @include media-breakpoint-down(lg) {
            margin: 2rem 0;
        }
    }

    footer{
        padding: 1rem 0;
        @extend .font-smallest;
        color: $mid_grey;
    }
}

