footer{
    margin-bottom: 1rem;
    background-color: $dark_grey;
    @extend .font-smallest;
}
.main-nav{
    footer{
        margin-top: 3rem;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        color: $mid_grey;
        font-size: 0.7rem;
        img{
            max-width: 40%;
        }
    }
}