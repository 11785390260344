.documents-folder, .documents-index, .documentstemplates-folder, .documentstemplates-index {

    .documents-folder-top{
        h2{
            position: relative;
            i{
                position: absolute;
                right: 0;
                top:-0.5rem;
                font-size: 2rem;
            }
        }
    }

    .folders-cont{
        min-height: 50vh;
        .panel{
            height: 100%;
            padding: 1rem;
            border-radius: 0;
            a:not(.btn){
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: nowrap;
                flex-direction: row;
                padding: 0.5rem 0.5rem 0rem 0.5rem;
                margin-bottom: 0.25rem;
                width: 100%;
                span{
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    padding: 0 0.5rem;
                }
                i{
                    color: $cornflow_blue;
                    &.large-icon{
                        position: relative;
                        top: -4px;
                    }
                    &.end{
                        position: relative;
                        top: 3px;
                        margin-left: auto;
                        color: $white;
                    }
                }
                &:hover{
                    color: $white;
                }
                &.active{
                    background-color: $white_10;
                    i{
                        &.end{
                            color: $cornflow_blue;
                        }
                    }
                    @extend .border-radius;
                    &:hover{
                        color: $dark_grey;
                    }
                }
            }
            &.parent-level{
                @extend .border-radius-left;
                a{
                    &:hover, &.active:hover{
                        color: $white;
                    }
                    &.back-btn{
                        position: relative;
                        top:-0.25rem;
                        padding: 0.1rem 0.25rem;
                        &:hover{
                            color: $dark_grey;
                        }
                    }
                }
            }
            &.current-level{
                background-color: $navy_grey;
                a{
                    &.active{
                        background-color: $white;
                    }
                }
            }
            &.child-level{
                position: relative;
                padding: 0;
                background-color: $white;
                color: $dark_grey;
                a:not(.btn){
                    &:hover{
                        color: $black;
                    }
                    &:hover{
                        background-color: $black_15;
                        @extend .border-radius;
                    }
                }
                a.btn{
                    margin-top: 2rem;
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -0.25rem;
                    width: 1px;
                    height: 100%;
                    background-color: $light_grey;
                    opacity: 0.25;
                }
            }
        }
        .files-cont{
            padding: 1rem;
            background-color: $white;
            color: $dark_grey;
            @extend .border-radius-right;
            h4{
                margin-bottom: 1.5rem;
            }
            h6{
                margin-bottom: 0;
                color: $green;
            }
            table{
                &.dataTable{
                    thead{
                        border-bottom: 1px solid $black_15;
                        th{
                            background-color: $white;
                            color: $navy;
                        }
                    }
                    tbody{
                            td:first-child{
                                a{
                                    color: $navy;
                                    &:hover{
                                        color: $green;
                                    }
                                    &::before{
                                        content: "\f15b";
                                        display: inline-block;
                                        font: normal normal normal 14px/1 FontAwesome;
                                        font-size: inherit;
                                        text-rendering: auto;
                                        -webkit-font-smoothing: antialiased;
                                        margin-right: 0.25rem;
                                    }
                                }
                                
                            }
            
                        }
                       
                    }
                    
                    
                }
                
            }
            
        
    }

    

}