#side-panel-modal{
    position: fixed;
    .modal-dialog{
        position: fixed;
        top: 0;
        right: 0;
        margin: 0;
        max-width: 50vw;
        height: 100vh;
        transform: translate(125%, 0px);
        @include media-breakpoint-down(lg) {
            max-width: 100vw;
        }
        &.modal-xl{
            width: 75vw;
            max-width: inherit;
            .panel-title{
                h2{
                    max-width: 65vw;
                }
            }
        }
        .modal-content{
            height: 100vh;
            padding: 0;
            border-radius: 0;
            border: none;
            background-color: $off_white;
            color: $white;

            table{
                border-bottom: 1px solid $light_grey;
            }

            .btn-close{
                margin: 1rem 2rem;
                z-index: 10;
                align-self: flex-end;
            }

            .modal-body{
                padding-top: 0;
                form{
                    .form-group{
                        img{
                            max-width: 150px;
                            &.form-image{
                                max-width: 120px;
                            }
                        }
                    }
                }
            }

            .panel-title{
                position: fixed;
                top: 1rem;
                h2{
                    max-width: 40vw;
                    @include media-breakpoint-down(lg) {
                        max-width: 75vw;
                    }
                }
            }
        }
        &.modal-dialog-dark{
            .modal-content{
                background-color: $dark_grey;
                color: $white;
                h2{
                    color: $white;
                }
                .accordion{
                    border-bottom:1px solid $light_grey;
                    .accordion-button{
                        padding: 1rem 0;
                        color: $white;
                        border:none;
                        border-top:1px solid $light_grey;
                        border-bottom:1px solid $light_grey;
                        background-color: $dark_grey;
                        &::after{
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z' fill='%23ffffff'/%3E%3C/svg%3E");
                        }
                        &.collapsed{
                            border-bottom:none;
                            &::after{
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z' fill='%23ffffff'/%3E%3C/svg%3E");
                            }
                        }
                    }
                    .accordion-item{
                        background-color: $dark_grey;
                        .accordion-body{
                            padding: 1rem 0;
                            .row{
                                padding: 0.25rem 0;
                            }
                        }
                    }
                }
                
            }
        }
    }

    &.show{
        .modal-dialog{
            transform: none;
        }
    }
}

/* FIX SCROLL ISSUE WHEN OPENING A NEW MODAL WINDOW */
body.modal-open { 
    position: fixed;
    overflow: hidden;
    .modal{
        position: inherit;
        background-color: transparent;
    }
}